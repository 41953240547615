* {
	font-family: "Montserrat", sans-serif;
}
@page {
	size: "A4";
}
.blue-box {
	padding-top: 1%;
	padding-bottom: 1%;
	margin: 0;
}
.ack-text {
	color: black;
	font-weight: 600;
	size: 20.9px;
	line-height: 31px;
	letter-spacing: 0.025em;
}

.rect-img-cont {
	width: 50%;
	height: 300px;
	z-index: 99;
	position: absolute;
	top: -74px;
	left: 45px;
}

.dashed-line {
	justify-content: center;
	align-items: center;
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
}

.partner-logo-img {
	width: 40%;
	position: absolute;
	left: 65px;
	top: 25px;
	z-index: 99999999;
}

.ack-below-box {
	background-color: #fff8f3;
	padding-top: 10px;
	padding-bottom: 10px;
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	min-height: 70px;
}

.company-deets {
	padding-left: 14%;
	font-size: 13px;
	letter-spacing: 0.02em;
	/* width: 60% !important; */
	margin-left: 200px;
}

.small-line {
	font-size: 10px;
	font-weight: 500;
}
.address {
	font-size: 13px;
	width: 100%;
	font-weight: 500;
	white-space: pre-wrap;
}
.cin {
	font-weight: 600;
}
.myclass-center {
	font-size: 13px;
	padding-left: 30px;
	padding-right: 15px;
	min-width: 250px;
	/* background-color: blue; */
}
.maincontent {
	margin-top: 60px;
	padding-left: 20px;
	padding-right: 20px;
}

.acknowledgement-num {
	display: flex;
	justify-content: space-around;
	padding-top: 0px;
	margin-left: 20px;
	margin-right: 20px;
	font-size: small;
}

.stud-details-box {
	margin-top: 20px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 20px;
	margin-bottom: 50px;
	width: 900px;
}
.stud-deet-td {
	text-align: left;
	padding-right: 3px;
	padding-left: 33px;
	padding-top: 0px;
	padding-bottom: 10px;
}

.pay-deets-1-tab {
	width: 900px;
	margin-bottom: 8px;
}

.pay-deets-1-tab td {
	width: 400px;
	border: 1px solid #2a3885;
	text-align: center;
	padding-right: 25px;
	padding-left: 25px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.pay-deets-2-tab {
	width: 800px;
}
.bottom-sheet {
	width: 100%;
	height: 45px;
	justify-content: center;
	display: flex;
}

.bottom-sheet-name {
	width: 40%;
	height: 45px;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 18px;
}

.pay-deets-2-tab td {
	width: 400px;
	border: 1px solid #2a3885;
	text-align: center;
	padding-right: 25px;
	padding-left: 25px;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #fff8f3;
}

.to-view {
	font-size: 13px;
	margin-top: 13px;
	/*margin-left: 50px;*/
}
.information {
	font-size: 15px;
	margin-top: 50px;
	/*margin-left: 50px;*/
}

.comp-gen {
	margin-top: 30px;
	margin-bottom: 20px;
}

.below-img {
	width: 100%;
}
.center-heading-value {
	word-break: break-all;
}
