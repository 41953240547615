.exam_button{
    border: 1px solid #858585;
	height: 40px;
	border-radius: 8px;
	max-width: 350px;
	width: 100%;
	padding: 3px 3px 3px 40px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #000000;
	background-color: #ffffff;
	outline: none;
    margin-right: 20px;
}